@charset 'UTF-8';

.header {
  position: relative;
  top: 0;
  z-index: 1000;
  height: 100%;
  transition: all 0.2s ease;
  @include PC {
    right: -330px;
  }
  @include SP {
    right: -240px;
  }
  .inHeader {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    z-index: 2000;
    height: 100%;
  }
  #logo {
    display: block;
    text-align: center;
    border-bottom: solid 1px #fff;
    margin-bottom: 57px;
    padding: 0 36px 42px 37px;
    @include add_prefix(transition, 0.3s ease all);
    img {
      display: block;
      width: 100px;
      height: 104px;
      margin: 0 auto;
      @include add_prefix(transition, 0.3s ease all);
    }
  }
  @include max-screen(767px) {
    #logo {
      padding: 0 39px 42px 34px;
      margin-bottom: 61px;
    }
  }
}

// .fixHeader {
  // .header {
  //   #logo {
  //     top: 72px;
  //     img {
  //       width: 108px;
  //       height: 26px;
  //     }
  //   }
  //   @include max-screen(767px) {
  //     #logo {
  //       top: 0;
  //       img {
  //         width: 80px;
  //         height: 20px;
  //       }
  //     }
  //   }
  // }
// }

.layerMenu {
  position: relative;
  z-index: 999;
  background: $color_body;
  width: 100%;
  max-width: 325px;
  padding: 54px 77px 0 75px;
  height: 100%;
  display: none;
  .gNavi {
    > li {
      display: block;
      vertical-align: top;
      width: auto;
      @include ffPlay;
      @include font(24,'',0,700);
      position: relative;
      padding-left: 1px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      > a {
        display: block;
        padding: 4px 0;
        position: relative;
        color: #fff;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background: #fff;
          transform: scaleX(0);
          @include add_prefix(transition, 0.3s ease all);
        }
        &:hover {
          &:after {
            transform: scaleX(1);
          }
        }
      }
      &.active {
        a {
          &:before {
            margin-top: -3px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  @include max-screen(767px) {
    padding: 54px 30px 0 33px;
    .inLayer {
      position: relative;
      height: 100%;
      overflow: auto;
    }
    .gNavi {
      margin-bottom: 30px;
      > li {
        padding-left: 0;
        margin-bottom: 25px;
        > a {
          padding: 0;
          &:hover {
            &:after {
              display: none;
            }
          }
        }
        &.active {
          > a {
            &:before {
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
  @include min-screen(768px) {
    position: relative !important;
    right: auto;
    top: auto;
  }
}

.inLayer {
  overflow-y: auto;
  height: 100%;
  padding-bottom: 58px;
  box-sizing: content-box;
}

.layerOn {
  overflow: hidden;
  touch-action:none;
  .is-ipad &, .is-ipad109 &, .is-ipad97 &, .ipad & {
    overflow: hidden;
    touch-action:none;
  }
  #wrap {
    overflow: hidden;
    touch-action:none;
  }
  .header {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1000;
    height: 100%;
    transition: all 0.4s ease-in-out;
  }
  .layerMenu {
    right: 0;
    display: block;
  }
  // #wrap {
  //   opacity: 0.51;
  //   transition: 0.3s ease;
  // }
}

.hamberger {
  position: fixed;
  z-index: 1000;
  line-height: 1;
  font-size: 0;
  text-align: center;
  cursor: pointer;
  display: block;
  @include PC {
    padding: 16px 8px 0 10px;
    width: 74px;
    height: 50px;
    right: 30px;
    top: 26px;
  }
  @include SP {
    padding: 8px 8px 0 10px;
    width: 44px;
    height: 40px;
    right: 9px;
    top: 6px;
  }
  span {
    display: inline-block;
    height: 1px;
    background: $color_body;
    @include add_prefix(transition, 0.3s ease all);
    @include PC {
      margin-bottom: 11px;
      width: 56px;
    }
    @include SP {
      margin-bottom: 7px;
      width: 28px;
    }
  }
  &.active {
    @include PC {
      right: 5px;
      top: 1px;
    }
    @include SP {
      right: 11px;
      top: 13px;
    }
    span {
      width: 33px;
      background: #fff;
    }
    .ham {
      @include PC {
        transform: rotate(30deg) translateY(11px) translateX(6px);
      }
      @include SP {
        transform: rotate(30deg) translateY(8px) translateX(4px);
      }
    }
    .ber {
      transform: rotate(-30deg);
    }
    .ger {
      display: none;
    }
  }
  &.white {
    span {
      background: #fff;
    }
  }
}
.overlay {
  opacity: 0;
  pointer-events: none !important;
  background-color: #fff;
  position: fixed;
  inset: 0;
  -webkit-transition:opacity .4s ease,z-index .1s;
  transition:opacity .4s ease, z-index .1s;  
  z-index: 400;
}
.layerOn {
  .overlay {
    opacity: 0.51;
  }
}
