@charset 'UTF-8';

.c-img {
  display: block;
  position: relative;
  img {
    display: block;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &--contain {
    img {
      object-fit: contain;
    }
  }
  &--top {
    img {
      object-position: top;
    }
  }
}

.c-fw {
  width: 100%;
}

[rel~="js-lazy"] {
  opacity: 0;
  &:not(.initial) {
    transition: opacity 0.15s;
  }
  &.initial,
  &.loaded,
  &.error {
    opacity: 1;
  }
}

.c-lazybg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &--top {
    background-position: center top;
  }
  &--contain {
    background-size: contain;
  }
}

.c-wcm01 {
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  @include PC {
    padding: 0 20px;
  }
  @include SP {
    padding: 0 15px;
  }
}
.c-wrap01 {
  @include PC {
    @include flexbox;
  }
}
.c-wrap02 {
  @include PC {
    @include flexbox;
    @include flex-wrap;
  }
}
.c-wrap03 {
  @include PC {
    @include flexbox;
    @include justify-content(space-between);
  }
}
.c-wrap04 {
  @include flexbox;
  @include flex-wrap;
}
.c-wrap05 {
  @include flexbox;
}
.c-wrap06 {
  @include flexbox;
  @include justify-content(space-between);
}
.c-img__box {
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 71.6%;
  }
  img {
    transition: 0.3s;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }
}
.c-ttl01 {
  @include ffPlay;
  @include font(46,'',0,700);
  @include SP {
    @include font(32);
    text-align: center;
  }
}