@charset 'UTF-8';

#footer {
  @include PC {
    padding: 102px 0 39px;
  }
  @include SP {
    padding: 125px 0 50px;
  }
  .c-wrap01 {
    @include PC {
      @include justify-content(space-between);
      @include align-items;
      position: relative;
    }
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $color_body;
      z-index: -1;
      top: 50%;
      left: 0;
      @include PC {
        display: block;
      }
      @include SP {
        display: none;
      }
    }
    .wrap-logo {
      @include PC {
        padding-right: 26px;
        background-color: #fff;
      }
      @include SP {
        margin-bottom: 12px;
      }
    }
    .wrap-copyright {
      padding-top: 1px;
      @include PC {
        padding-left: 33px;
        background-color: #fff;
      }
      .txt {
        @include ffPlay;
        @include font(14,'',50,700);
        @include SP {
          @include ANDR {
            @include font(12);
          }
        }
      }
    }
  }
  .to-page-top {
    position: fixed;
    transform: rotate(-90deg);
    cursor: pointer;
    z-index: 399;
    opacity: 0;
    transition: all 0.3s;
    @include PC {
      bottom: 105px;
      right: -11px;
    }
    @include SP {
      bottom: 174px;
      right: -35px;
    }
    .txt {
      @include ffPlay;
      @include font(14,'',50,700);
      padding-right: 40px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        @include bgimg ($img_url + "top/all_arr.svg", no-repeat, center, transparent, 100% 100%);
        width: 13px;
        height: 24px;
        top: calc(50% - 13px);
        right: 0;
      }
    }
    &.show-btn {
      opacity: 1;
    }
  }
}